import React from 'react'
import contact_img from '../../images/contact_img.jpg'
import { Link } from "react-router-dom";

function ContactComponent() {
    function scrollToTop() {
        const element = document.getElementById("top");
        if (element) {
            element.scrollIntoView({
                behavior: "smooth", // Add smooth scrolling behavior
            });
        }
    }
    return (

        <>
            <section className="wrapper bg-dark text-white">
                <div className="container pt-7 pt-md-10 pb-18 pb-md-21 text-center">
                    <div className="row page-top-banner">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto my-4">
                            <h1 className="display-1 text-white mb-3 head-title">Contact Us</h1>
                            <p className="lead px-xl-10 px-xxl-10">Have any questions? Reach out to us from our contact form and we will get back to you shortly.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col mt-n19 mb-16 my-5">
                            <div className="card shadow-lg">
                                <div className="row gx-0">
                                    <img src={contact_img} alt='contact us' className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-md-block" />
                                    <div className="col-lg-6">
                                        <div className="p-10 p-md-6 p-lg-8">
                                            <h2 className="display-4 mb-3 mx-4 my-4">Let's Talk</h2>
                                            <p className="lead fs-lg mx-4 my-4">Let's make something great together. We are trusted by many clients. Join them by using our services and grow your business.</p>
                                        </div>
                                        <div className="px-10 px-md-6 px-lg-8 pt-0">
                                            <div className="d-flex flex-row">
                                                <div className='container mx-3'>
                                                    <div className="row">
                                                        <div className="col-2 py-3  justify-content-end">
                                                            <i className="fa-solid fa-address-card fa-2xl"></i>
                                                        </div>
                                                        <div className="col-10 text-start contact-address">
                                                            <h5 className="mb-1 text-start">Address</h5>
                                                            <address>
                                                                Register Office: <br />
                                                                ST Global 1100 State Route 222 Bethel, OH 45106
                                                            </address>

                                                            <address>
                                                                Corporate Office / Mailing Address: <br />
                                                                1200, Camp Hill Bypass, Suite 300, Camphill, <br />
                                                                PA-17011
                                                            </address>

                                                            <address>
                                                                India Office: <br />
                                                                A-25 Sector-63 Noida, 201301
                                                            </address>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <div className='container mx-3'>
                                                    <div className="row">
                                                        <div className="col-2 py-3  justify-content-end">
                                                            <i className="fa-solid fa-envelope fa-2xl"></i>
                                                        </div>
                                                        <div className="col-10">
                                                            <h5 className="mb-1 text-start">Email</h5>
                                                            <address>
                                                                <a className='' href="mailto:info@stglobaltech.com">info@stglobaltech.com</a>

                                                            </address>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-10 gx-lg-8 gx-xl-12">
                        <div className="col-md-12 col-lg-6 my-5">
                            <form novalidate="" className="contact-form needs-validation ng-untouched ng-pristine ng-invalid">
                                <div className="row gx-4">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="text" name="value" gform="" placeholder="Name" minlength="2" maxlength="50" required="" className="form-control border ng-untouched ng-pristine ng-invalid" />
                                            <label htmlFor="form_name">Name *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please enter your first name. </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="text" name="email" gform="" placeholder="jane.doe@example.com" minlength="3" maxlength="50" className="form-control border ng-untouched ng-pristine ng-valid" />
                                            <label htmlFor="form_email">Email *</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="text" name="subject" gform="" placeholder="subject" minlength="4" maxlength="50" required="" className="form-control border ng-untouched ng-pristine ng-invalid" />
                                            <label htmlFor="form_dept">Subject *</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input name="inputFile" id="inputFile" type="file" className="file-upload" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-4">
                                            <textarea id="form_message" name="message" placeholder="Your message" required="" className="form-control border ng-untouched ng-pristine ng-invalid"></textarea>
                                            <label htmlFor="form_message">Message *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please enter your messsage. </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="agreeCheckbox" style={{ border: '2px solid #808080' }} />
                                            <label className="form-check-label" htmlFor="agreeCheckbox">
                                                I agree to the <Link className='text-decoration-none' to='/contact/privacypolicy' onClick={() => { scrollToTop() }}>privacy policy</Link>,
                                                <Link className='text-decoration-none' to='/contact/termsandcondition' onClick={() => { scrollToTop() }}> terms</Link> as applicable, and would like to receive SMS, updates, and information from ST Global. SMS rates may apply.
                                            </label>
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <button type="submit" value="Send message" className="btn rounded-pill btn-send mb-3">Send Message</button>
                                        <p className="text-muted">
                                            <strong>*</strong> These fields are required.
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-12 col-lg-6 my-5">
                            <div className="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24363.229756021006!2d-76.94639342910688!3d40.24455822390672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8c22457cb4a7f%3A0x906e377fd98013b0!2sCamp%20Hill%2C%20PA%2017011%2C%20USA!5e0!3m2!1sen!2sin!4v1702222218075!5m2!1sen!2sin" width="100%" height="390" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Location'></iframe>
                                {/* <iframe src= width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section> </>
    );
}

export default ContactComponent;