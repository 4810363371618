import React from "react";
import ourteam_img from "../../images/ourTeam.jpg";
import founder_img from "../../images/FounderProfile.jpeg";
import cofounder_img from "../../images/CoFounderProfile.jpeg";
import emp01_img from "../../images/emp01.jpeg";
import emp02_img from "../../images/emp02.jpeg";
import emp03_img from "../../images/emp03.jpeg";
import "./TeamComponent.css"; // Import your custom styles

const teamMembers = [
  {
    id: 1,
    name: "Rohit Goel",
    imgSrc: emp01_img,
    role: "Vice President-Sales",
    emp_linkedin: "https://www.linkedin.com/in/rohit-goel123/",
    emp_department: "For Sales",
    emp_mail: "rohit@stglobaltech.com"
  },
  {
    id: 2,
    name: "Himani Dureja",
    imgSrc: emp02_img,
    role: "Senior Business Development",
    emp_linkedin: "https://www.linkedin.com/in/himani-dureja-78045636/",
    emp_department: "For Engineering Staffing",
    emp_mail: "himani@stglobaltech.com"
  },
  {
    id: 3,
    name: "Farzan Siddiqui",
    imgSrc: emp03_img,
    role: "Senior Business Development",
    emp_linkedin: "https://www.linkedin.com/in/farzansiddiqui/",
    emp_department: "For New Clients",
    emp_mail: "farzan@stglobaltech.com"
  },

  // Add more team members as needed
];

const TeamMember = ({ id, name, imgSrc, role, emp_linkedin, emp_department, emp_mail }) => (
  <div className="col-sm-12 col-md-6 col-lg-4" key={id}>
    <div className="card mb-3 team-card">
      <div className="row g-0 align-items-center justify-content-center">
        <div className="col-md-6">
          <img src={imgSrc} className="img-fluid rounded-start" alt={name} />
        </div>
        <div className="col-md-6">
          <div className="card-body">
            <h5 className="card-title">{name}&nbsp;<span><a href={emp_linkedin} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin" style={{ color: "#005ca3" }}></i></a></span>
            </h5>
            <p className="card-text">{role}</p>
            <div>
              <p>{emp_department}&nbsp;</p>
              <a className="text-decoration-none" href={`mailto:${emp_mail}`} target="_blank" rel="noopener noreferrer">Contact Me</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

function TeamComponent() {
  return (
    <>
      <div className="team-header">
        <div className="pb-4 pb-md-2 text-center">
          <div className="row">
            <div className="card p-0 position-relative">
              <img
                src={ourteam_img}
                alt=""
                className="img-fluid gradient-overlay" 
                style={{maxHeight : "350px"}}
              />
              <div className="card-img-overlay d-flex justify-content-center align-items-center">
                <span className="text-white p-md-2 display-4 head-title">
                  Our Team is the power of our innovation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="container wrapper bg-light my-5">
            <div className="row my-2 align-items-center justify-content-center">
              <div className="col-lg-4">
                <img
                  className="w-100 rounded"
                  src={founder_img}
                  alt="Founder"
                />
              </div>
              <div className="col-lg-8">
                <div className="d-flex align-items-center team-founder">
                  <h1 className="">Sanjay S Thakur</h1>
                  <a className="ms-4"
                    href={`https://www.linkedin.com/in/thakur-sanjay-18171b6/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <i
                        className="fa-brands fa-2xl fa-linkedin"
                        style={{ color: "#005ca3" }}
                      ></i>
                    </span>
                  </a>
                </div>
                <h4>Founder</h4>
                <p>For IT Consulting and Healthcare Services and Software Development Engineering <a className="text-decoration-none" href="mailto:info@stglobaltech.com">Contact Me</a></p> 
                <p>
                  Mr. Thakur, with 18 years in IT, excels in Enterprise Business
                  and Technical Analysis, Product Ownership, and Project
                  Management. Specializing in data governance, metadata
                  management, and Agile methodologies, he achieves project
                  success with strategic planning and strong leadership.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="container wrapper bg-light my-5">
            <div className="row my-2 align-items-center justify-content-center">
              <div className="col-lg-4">
                <img
                  className="w-100 rounded"
                  src={cofounder_img}
                  alt="Founder"
                />
              </div>
              <div className="col-lg-8 ">
              <div className="d-flex align-items-center team-founder">
                <h1 className="">Pramod Kumar</h1>
                <a className="ms-4"
                  href={`https://www.linkedin.com/in/pramodkumar79/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <i
                      className="fa-brands fa-2xl fa-linkedin"
                      style={{ color: "#005ca3" }}
                    ></i>
                  </span>
                </a>
                </div>
                <h4>Co-Founder</h4>
                <p>For IT Staffing and Recruitement Process Outsourcing <a className="text-decoration-none"href="mailto:pankaj@stglobaltech.com">Contact Me</a></p>
                <p>
                  With over 8 years in IT recruitment, including 6 years
                  focusing on the US market, Mr. Kumar excels in gathering
                  requirements, sourcing, screening, and selecting candidates. A
                  contract negotiation expert, he utilizes online job boards for
                  diverse talent acquisition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container wrapper my-4 text-center">
        <h1>Pillars of Team</h1>
        <div className="container my-3">
          <div className="row">
            {teamMembers.map((member) => (
              <TeamMember {...member} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamComponent;
