import React from 'react'

function TermsComponent() {
  return (
    <>
    <div className="container mt-5">
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <h2 className="text-center mb-4">Terms of Service</h2>

                <p>
                    The Terms of Service (“Terms”) for the use of our website ST Global (“Website”), products, and services (“Services”), are between ST Global Technologies Ltd. (“Company/We/Us/Our”) and its users (“User/You/Your”). These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000, and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time. Please read the Terms and the privacy policy of the Company (“Privacy Policy”) available at [Privacy Policy URL] carefully before accessing or using our Website or Our services provided thereunder. In the event of any discrepancy between the Terms and any other policies with respect to the Website or Our services provided thereunder, the Terms shall prevail. Your use or access to the Website or Our services (with or without payment/with or without subscription) through any means shall signify your acceptance of the Terms and Your agreement to be legally bound by the same.
                </p>

                                       {/* Continue modifying the content as per your specific requirements */}
                                       <p>
                            <strong>PURPOSE:</strong>
                            Our Website is intended to serve as a platform for information exchange and interaction for users with a bona fide interest in our products, services, and career opportunities. The Services include, but are not limited to, accessing job vacancies and career enhancement services.
                        </p>

                        <p>
                            <strong>INTELLECTUAL PROPERTY:</strong>
                            Except as expressly permitted, no part of this Website or Services may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any form without obtaining prior written permission from ST Global. The Company retains full and complete title to all intellectual property rights related to the Website and Services.
                        </p>

                        <p>
                            <strong>USER-GENERATED CONTENT:</strong>
                            By submitting content through the Website or Services, you grant ST Global a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute the submitted material. ST Global reserves the right to associate your material with you, except as described otherwise.
                        </p>

                                            {/* ...

                        ... (Previous sections remain unchanged)

                        ...
                    */}

                    {/* Additional Section: User Responsibilities */}
                    <p>
                        <strong>USER RESPONSIBILITIES:</strong>
                        {/* Add content specific to user responsibilities, e.g., adherence to community guidelines, ethical use, etc. */}
                        As a user of our Website and Services, you are responsible for adhering to community guidelines, ensuring ethical use, and complying with applicable laws. Any misuse or violation may lead to suspension or termination of your account.
                    </p>

                    {/* Additional Section: Governing Law and Dispute Resolution */}
                    <p>
                        <strong>GOVERNING LAW AND DISPUTE RESOLUTION:</strong>
                        {/* Include information related to the governing law and dispute resolution process. */}
                        The Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the competent courts located in Chennai, and you hereby accede to and accept the jurisdiction of such courts.
                    </p>

                                       {/* Additional Section: Termination of Services */}
                                       <p>
                        <strong>TERMINATION OF SERVICES:</strong>
                        {/* Include information about the conditions under which services may be terminated and the consequences of termination. */}
                        We reserve the right to terminate your access to our Services under certain conditions, including but not limited to violation of our Terms or misuse of the platform. Termination may result in the removal of your content and other related information.

                        {/* Additional details specific to ST Global can be added here. */}
                    </p>

                    {/* Additional Section: Contact Information */}
                    <p>
                        <strong>CONTACT INFORMATION:</strong>
                        {/* Provide contact details for users to reach out for support, inquiries, or other matters. */}
                        For any questions, concerns, or support-related inquiries, you can contact us at [insert contact email/phone number]. We value your feedback and are here to assist you.

                        {/* Additional details specific to ST Global can be added here. */}
                    </p>
                    

                        
                        <p>
                            <strong>DISCLAIMER:</strong>
                            THIS WEBSITE AND THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS AND WITHOUT ANY WARRANTY OF ANY KIND. ST GLOBAL DISCLAIMS ALL WARRANTIES AND CONDITIONS REGARDING THE WEBSITE AND SERVICES, INCLUDING IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY, TIMELINESS, PERFORMANCE, COMPLETENESS, SUITABILITY, AND NON-INFRINGEMENT. ST GLOBAL SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS WEBSITE OR THE SERVICES.
                        </p>

                       {/* General Provisions */}
<p>
    <strong>NOTICE:</strong>
    All notices served by the Company shall be provided via email to Your account or as a general notification on the Website. Any notice to be provided to the Company should be sent to 8/57, Luz Avenue Road, Mylapore, Chennai – 600 004, India.
</p>

<p>
    <strong>ENTIRE AGREEMENT:</strong>
    The Terms, along with the Privacy Policy, and any other guidelines made applicable to the Website or the Services from time to time, constitute the entire agreement between the Company and You with respect to Your access to or use of the Website and the Services thereof.
</p>

<p>
    <strong>ASSIGNMENT:</strong>
    You cannot assign or otherwise transfer Your obligations under the Terms or any right granted hereunder to any third party. The Company may freely transfer the Terms to any third parties without Your consent.
</p>

<p>
    <strong>SEVERABILITY:</strong>
    If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.
</p>

<p>
    <strong>WAIVER:</strong>
    Any failure by the Company to enforce or exercise any provision of the Terms or any related right shall not constitute a waiver by the Company of that provision or right.
</p>

<p>
    <strong>RELATIONSHIP:</strong>
    You acknowledge that Your participation on the Website or the use of Services does not make You an employee, agency, partnership, joint venture, or franchise of the Company.
</p>

<p>
    The Company provides these Terms so that You are aware of the terms that apply to your use of the Website and Services. You acknowledge that the Company has given You a reasonable opportunity to review these Terms and that You have agreed to them.
</p>

<p>
    <strong>FEEDBACK AND PROPRIETARY INFORMATION:</strong>
    Any feedback You provide with respect to the Website or the Services shall be deemed to be Company Proprietary Information. The Company shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or proprietary information of You or of third parties; (ii) the Company is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) the Website or the Services may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from the Company for the feedback under any circumstances unless specified.
</p>

<p>
    <strong>USER-GENERATED CONTENT DISCLAIMER:</strong>
    Under no circumstances shall the Company be held responsible in any manner for any content provided by other users, even if such content is offensive or hurtful. Please exercise caution while accessing the Website or the Services.
</p>
{/* ... (Continue adding more sections as necessary) */}

                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsComponent
;
