import "./Navbar.css";
import logo from "../../images/stg_logo.png";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [clicked, setClicked] = useState(false);
  function scrollToTop() {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
      });
    }
  }
  const handleMenuClick = () => {
    setClicked(!clicked);
  };

  const closeMenu = () => {
    setClicked(false);
  };

  const changeSiteTitle = (title_half) => {
    document.title = 'ST Global - ' + title_half;
  }

  return (
    <>
      <nav className="nav-sticky navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid navbar-color">
          <NavLink className="navbar-brand" to="/" onClick={() => { scrollToTop(); }}>
            <img src={logo} alt="ST Global" className="logo_img logo_nav" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={handleMenuClick}>
            <span className="">
              <i id="bar" className={`${clicked ? "fas fa-times" : "fas fa-bars"}`}></i>
            </span>
          </button>
          <div className={`collapse navbar-collapse ${clicked ? "navbar-mobile active" : "navbar"}`} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/" exact="true" activeClassName="active" className="nav-link" onClick={() => { changeSiteTitle('Home'); closeMenu(); scrollToTop(); }} >HOME</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" exact="true" activeClassName="active" className="nav-link" onClick={() => { changeSiteTitle('About'); closeMenu(); scrollToTop(); }}> ABOUT</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/ourTeam" exact="true" activeClassName="active" className="nav-link" onClick={() => { changeSiteTitle('Our team'); closeMenu(); scrollToTop(); }}> OUR TEAM </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link" href="/service" aria-disabled='true' role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  SERVICE
                </NavLink>
                <ul className="dropdown-menu navbar-color">
                  <li className="navbar-color p-0 m-0"><NavLink exact="true" activeClassName="active" className="nav-link navbar-color" to='/service/itconsulting' onClick={() => { changeSiteTitle('Services'); closeMenu(); scrollToTop(); }}>IT Consulting and Staffing</NavLink></li>
                  <li className="navbar-color p-0 m-0"><NavLink exact="true" activeClassName="active" className="nav-link navbar-color" to='/service/softwaredevelopment' onClick={() => { changeSiteTitle('Services'); closeMenu(); scrollToTop(); }}>Software Development</NavLink></li>
                  <li className="navbar-color p-0 m-0"><NavLink exact="true" activeClassName="active" className="nav-link navbar-color" to='/service/engineeringstaffing' onClick={() => { changeSiteTitle('Services'); closeMenu(); scrollToTop(); }}>Engineering Staffing</NavLink></li>
                  <li className="navbar-color p-0 m-0"><NavLink exact="true" activeClassName="active" className="nav-link navbar-color" to='/service/rpocontent' onClick={() => { changeSiteTitle('Services'); closeMenu(); scrollToTop(); }}>Recruitement Process Outsourcing</NavLink></li>
                  <li className="navbar-color p-0 m-0"><NavLink exact="true" activeClassName="active" className="nav-link navbar-color" to='/service/healthcarestaffing' onClick={() => { changeSiteTitle('Services'); closeMenu(); scrollToTop(); }}>Healthcare Consulting and Staffing</NavLink></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/career" exact="true" activeClassName="active" className="nav-link" onClick={() => { changeSiteTitle('Career'); closeMenu(); scrollToTop(); }}> CAREER </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" exact="true" activeClassName="active" className="nav-link" onClick={() => { changeSiteTitle('Contact'); closeMenu(); scrollToTop(); }}> CONTACT</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <nav>
        <NavLink className="logo" to="/">
          <img src={logo} alt="ST Global" className="logo_img" />
        </NavLink>
        <div>
          <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
              <NavLink to="/" exact="true" activeClassName="active"  onClick={() => changeSiteTitle('Home')}> Home </NavLink>
              <NavLink to="/service" exact="true" activeClassName="active" onClick={() => changeSiteTitle('Service')}> Service </NavLink>
              <NavLink to="/ourTeam" exact="true" activeClassName="active" onClick={() => changeSiteTitle('Our team')}> Our Team </NavLink>
              <NavLink to="/career" exact="true" activeClassName="active" onClick={() => changeSiteTitle('Career')}> Career </NavLink>
              <NavLink to="/about" exact="true" activeClassName="active" onClick={() => changeSiteTitle('About')}> About </NavLink>
              <NavLink to="/contact" exact="true" activeClassName="active" onClick={() => changeSiteTitle('Contact')}> Contact </NavLink>
          </ul>
        </div>

        <div id="mobile" onClick={handleMenuClick}>
          <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav> */}
    </>
  );
}

export default Navbar;