import React from "react";
import Engineering_img1 from '../../images/es_pic1.jpg'
import servicePage1 from '../../images/ServiceComponentES.jpg'
import "./ServiceComponentES.css"; // Import a CSS file for styling

export default function ServiceComponentES() {
  return (
    <div>
      <div className=''>
        <div className=" pb-4 pb-md-2 text-center">
                <div className="row">
                    <div className="card p-0">
                        <img src={servicePage1} alt="" style={{maxHeight : "350px"}} />
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div className="service-es-container container my-3">
        <div className="row">
          <div className="col-lg-6  align-self-center justify-content-center ">
            <h1>What is Engineering Staffing?</h1>
            <p>
              In an ever-evolving technological landscape, sourcing and retaining
              top engineering talent is critical for the success of your projects.
              At ST Global, we understand the unique challenges faced by
              organizations in the engineering sector. Our Engineering Staffing
              Services are designed to provide you with the right expertise,
              precisely when you need it, ensuring seamless project execution and
              success.
            </p>
          </div>
          <div className="col-lg-6">
            <div className="container text-center">
              <img src={Engineering_img1} className= 'image_size' alt=""/>
            </div>
          </div>
        </div>
       
        <div className="my-3">
        <ul className="custom-list">
          <li>
            <b>Specialized Expertise: </b>
            <span>
              Our extensive network includes highly skilled engineers with
              expertise in various engineering disciplines, ensuring that we can
              match your specific project requirements with the right
              professionals.
            </span>
          </li>
          <li>
            <b>Global Reach: </b>
            <span>
              With a global presence, we have access to a diverse pool of
              engineering talent. Whether you need support for a local project
              or require specialized skills from across the globe, we have the
              resources to meet your needs.
            </span>
          </li>
          <li>
            <b>Cost-Effective Solutions: </b>
            <span>
              Save on recruitment costs and time by leveraging our pre-vetted
              pool of engineering professionals. Our flexible staffing solutions
              allow you to scale your team up or down based on project demands,
              optimizing costs without compromising on quality.
            </span>
          </li>
          <li>
            <b>Streamlined Recruitment Process: </b>
            <span>
              Our meticulous staffing process involves understanding your
              project requirements in detail, identifying candidates with the
              right technical skills, and ensuring a seamless onboarding process
              for a quick and efficient start.
            </span>
          </li>
        </ul>
        </div>
        <div className="mt-1">
          <h4>Our Engineering Staffing Process:</h4>
          <ul className="custom-list">
            <li>
              <b>Understand: </b>
              <span>
                Our process begins with a thorough understanding of your project
                requirements. We collaborate with your team to grasp the
                technical skills, project scope, and cultural nuances crucial
                for a successful match.
              </span>
            </li>
            <li>
              <b>Identify: </b>
              <span>
                Utilizing our vast network and recruitment expertise, we
                identify and evaluate candidates with the precise skills and
                experience needed for your engineering projects. Our rigorous
                screening process ensures that only the most qualified
                professionals are presented to you.
              </span>
            </li>
            <li>
              <b>Support: </b>
              <span>
                From interview preparation to onboarding, we provide
                comprehensive support throughout the entire engagement. Our
                dedicated team ensures that both the client and the candidate
                experience a seamless integration, fostering a productive and
                positive working relationship.
              </span>
            </li>
            <li>
              <b>Quality Assurance: </b>
              <span>
                We prioritize quality in our service delivery. Regular check-ins
                and performance evaluations are conducted to guarantee that our
                engineering professionals consistently meet and exceed your
                expectations.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
