import React from 'react';

function PrivacyComponent() {
    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="">
                            <div className="">
                                <h2 className="card-title text-center mb-4">Privacy Policy</h2>
                                <p className="card-text text-justify">
                                    At ST Global, we highly value your privacy and are committed to safeguarding your personal information. This Privacy Policy outlines how ST Global and its affiliates ("we," "us," "our," "ST Global") collect, use, share, and secure your information in connection with our services.
                                </p>

                                <p className="card-text text-justify">
                                    Protecting Your Privacy: This Privacy Policy elaborates on how ST Global and certain affiliates (“we,” “us,” “our,” “ST Global”) collect, use, share, and protect information in connection with ST Global’s services, which are referred to or linked to this Privacy Policy (“our Services”). It is applicable regardless of the type of device you use to access our Services.
                                </p>

                                <p className="card-text text-justify">
                                    ST Global reserves the right to modify this Privacy Policy at any time by posting the updated policy along with the date of the modification.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Information Collection and Sources</strong>: We collect and maintain personal information from various sources to understand and meet your needs, set up solutions, process transactions, manage our business, and for other disclosed purposes. For instance, we collect personal information from:
                                </p>

                                <ul className="card-text">
                                    <li>You, when you voluntarily provide us with information.</li>
                                    <li>Your transactions with ST Global.</li>
                                    <li>Other third-party sources, such as recruitment agencies when seeking potential employees.</li>
                                </ul>

                                <p className="card-text text-justify">
                                    You may, in some cases, have the option to decline providing information directly to us. However, not providing certain information may impact your use of specific features or services.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Sharing Your Information</strong>: ST Global does not share personal information with third parties except as stated in this Privacy Policy. We may disclose information to affiliated companies and unaffiliated third parties for administrative, analytical, and marketing purposes.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Legal Requirements</strong>: Laws and regulations may require us to provide access to the personal information you disclose and data we have about you. We may disclose personal information to government authorities or third parties as required by law.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Obtaining and Handling Consent</strong>: By using our services or providing personal information to ST Global, you expressly consent to the collection, use, disclosure, and retention of your personal data as described in this Privacy Policy.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Application of Local Laws</strong>: Where required by local law, you may have the right to access, update, or request the deletion of your personal information. Requests for corrections, transfers, or deletions are subject to legal, ethical, or document retention obligations.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Links to Other Sites</strong>: ST Global provides additional links to external resources. Visitors are responsible for reviewing privacy policies of these sites, as we have no control over information submitted to them.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Your Rights in Detail</strong>:
                                </p>

                                <ol className="card-text">
                                    <li><strong>Right of Access:</strong> Obtain confirmation and access to personal data.</li>
                                    <li><strong>Right to be Informed:</strong> Know the purposes, retention periods, and recipients of your personal data.</li>
                                    <li><strong>Right to Rectification:</strong> Correct inaccurate personal data.</li>
                                    <li><strong>Right to Erasure:</strong> Request erasure of personal data under certain circumstances.</li>
                                    <li><strong>Right to Restriction of Processing:</strong> Obtain restriction of processing under specific conditions.</li>
                                    <li><strong>Right to Data Portability:</strong> Receive personal data in a machine-readable format and transmit it to another entity.</li>
                                    <li><strong>Right to Object:</strong> Object to processing based on legitimate interests or for direct marketing.</li>
                                </ol>

                                <p className="card-text text-justify">
                                    <strong>Opt-out Options</strong>: You may opt-out of promotional emails or marketing communications by contacting our customer support team at <a href="mailto:info@stglobaltech.com">info@stglobaltech.com</a>.
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Contact Us</strong>: For questions, comments, or requests regarding our privacy practices, contact us at <a href="mailto:info@stglobaltech.com">info@stglobaltech.com</a>. Alternatively, you can mail inquiries to:
                                </p>

                                <p className="card-text">
                                    Address:<br />
                                    
                                    Register Office<br />
                                    ST Global
                                    1100 State Route 222 Bethel, OH 45106
                                </p>

                                <p className="card-text text-justify">
                                    Corporate Office / Mailing Address:<br />
                                    1200, Camp Hill Bypass, Suite 300, Camphill PA-17011
                                </p>

                                <p className="card-text text-justify">
                                    <strong>Effective Date</strong>: Last updated and effective on 04/02/2023.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyComponent;
