import React from 'react'
import './About.css'
import about_img from '../../images/About_img.jpg'
import about_img2 from '../../images/about_img2.png'

function AboutComponent() {
    return (
        <div className=''>
            <div className=" pb-4 pb-md-2 text-center">
                <div className="row">
                    <div className="card p-0">
                        <img src={about_img} alt="" style={{maxHeight : "350px"}} />
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4 pb-7 pb-md-8">
                <div className="row">
                    <div className="col-lg-6 order-lg-2">
                        <ul className="progress-list list-unstyled">
                            <li>
                                <p>Design</p>
                                <div className="progress progress-1">
                                    <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-striped w-98">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p>Development</p>
                                <div className="progress progress-2">
                                    <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-striped w-98">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p>Cloud</p>
                                <div className="progress progress-3">
                                    <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-striped w-98">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p>Data Analysis</p>
                                <div className="progress progress-4">
                                    <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-striped w-98">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <p className='lh-lg' style={{ textAlign: 'justify', textJustify: 'inter-word' }}> ST Global is a dynamic service
                            & product development company with a passionate & exceptionally
                            talented team, dedicated to serving customers worldwide.
                            Our expertise spans across diverse domains, including Automation,
                            SmartCity, IoT, mobile application development, & web technologies.
                            Embracing best practices in agile methodology, we deliver end-to-end products,
                            processes, & solutions, ensuring an unparalleled "Great Experience" for our valued clients. Innovation and excellence drive us forward as we continue to create transformative solutions that leave a lasting impact in today's rapidly evolving digital landscape.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row gx-lg-8 gx-xl-12 gy-md-0 my-5 text-center">
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div className="card about-card p-4 h-100 w-100">
                            <i className='fa-brands fa-sketch fa-3x' style={{ color: "#176B87" }}></i>
                            <h4 className='my-4'>Design</h4>
                            <p className='mb-2 '>
                                At ST Global, our human-centric approach lies at the heart
                                of our mission to create functional designs that deliver a remarkable User Experience.
                                Our solutions are characterized by scalability, modernity, cost-efficiency,
                                and innovation, ensuring your digital presence stands out in today's competitive landscape.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div className="card about-card p-4 h-100 w-100">
                            <i className="fa-solid fa-computer fa-3x" style={{ color: "#176B87" }}></i>
                            <h4 className='my-4'>Development</h4>
                            <p className='mb-2 '>
                                At ST Global, we are dedicated to advancing innovation in development with a human-centric focus. Our mission is to engineer solutions that embody scalability, modernity, cost-efficiency, and cutting-edge technology. Elevate your digital footprint in today's competitive landscape with our forward-thinking development expertise.
                            </p>
                        </div>
                    </div>

                    {/* <div className="row gx-lg-8 gx-xl-12 gy-md-0 my-3 text-center"> */}
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div className="card about-card p-4 h-100 w-100">
                            <i className='fa-solid fa-cloud text-blue fa-3x' style={{ color: "#176B87" }}></i>
                            <h4 className='my-4'>Cloud</h4>
                            <p className='mb-2 '>
                                At ST Global, we soar above the clouds in our commitment to revolutionizing the digital sphere. Embracing a cloud-centric approach, our mission is to architect solutions that seamlessly harness the power of cloud technology. From scalability and security to efficiency and innovation, trust ST Global to elevate your digital presence amidst the limitless possibilities of the cloud.</p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <div className="card about-card p-4 h-100 w-100">
                            <i className='fa-solid fa-chart-simple text-blue fa-3x' style={{ color: "#176B87" }}></i>
                            <h4 className='my-4'>Data Analysis</h4>
                            <p className='mb-2 '>
                                At ST Global, we dive deep into the data-driven realm, where our commitment to excellence in data analysis takes center stage. Our mission is to craft insightful solutions that unravel the hidden potential within your data. Characterized by precision, scalability, and innovation, our data analysis services ensure that your business makes informed decisions and stays ahead in today's dynamic landscape.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5 py-14 py-md-16">
                <div className="row">
                    <div className="col-lg-7">
                        <figure>
                            <img className='w-100' src={about_img2} alt="" />
                        </figure>
                    </div>
                    <div className="col-lg-4 ps-5">
                        <h2 className="fs-15 text-uppercase text-line text-center mb-3" style={{ color: "#176B87" }}>
                            GET IN TOUCH
                        </h2>
                        <h3 className="text-center">
                            Got any Questions? Don't hesitate to get in touch.
                        </h3>
                        <div className="row my-4">
                            <div className='container mx-3'>
                                <div className="row">
                                    <div className="col-1 py-2 me-4 mx-3">
                                        <i className="fa-solid fa-address-card fa-2xl"></i>
                                    </div>
                                    <div className="col-8">
                                        <h5 className="mb-1">Address</h5>
                                        <address>
                                            <u>Register Office</u><br />ST Global 1100 State Route 222 Bethel, OH 45106 <br/>
                                            <u>Corporate Office / Mailing Address</u><br />1200, Camp Hill Bypass, Suite 300, Camphill PA-17011
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className='container mx-3'>
                                <div className="row">
                                    <div className="col-1 py-2 me-4 mx-3">
                                        <i className="fa-solid fa-phone fa-2xl"></i>
                                    </div>
                                    <div className="col-7">
                                        <h5 className="mb-1">Phone Number</h5>
                                        <address> +1-513-729-7021<br /> </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className='container mx-3'>
                                <div className="row">
                                    <div className="col-1 py-2 me-4 mx-3">
                                        <i className="fa-solid fa-envelope fa-2xl"></i>
                                    </div>
                                    <div className="col-7">
                                        <h5 className="mb-1">Email Address</h5>
                                        <a href="mailto:info@stglobaltech.com">info@stglobaltech.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutComponent;