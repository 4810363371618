import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import HomeComponent from "./components/Home/HomeComponent";
import CareerComponent from "./components/Career/CareerComponent";
import ServicesComponent from "./components/Service/ServicesComponent";
import ContactComponent from "./components/Contact/ContactComponent";
import AboutComponent from "./components/About/AboutComponent";
import ServiceComponent1 from "./components/Service/ServiceComponentITCS";
import ServiceComponent2 from "./components/Service/ServiceComponentSD";
import ServiceComponent3 from "./components/Service/ServiceComponentES";
import ServiceComponent4 from "./components/Service/ServiceComponentRPO";
import ServiceComponent5 from "./components/Service/ServiceComponentHCS";
import PrivacyComponent from "./components/PrivacyTerms/PrivacyComponent";
import TermsComponent from "./components/PrivacyTerms/TermsComponent"; 
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TeamComponent from "./components/OurTeam/TeamComponent";

function App() {
  return (
    <BrowserRouter>
      <div className="App" id="top">
        <Navbar />
        <div className="nav-sticky-padded">
          <div className="App-body">
            <Routes>
              <Route path="/" element={<HomeComponent />} />
              <Route path="/service" element={<ServicesComponent />} />
              <Route path="/ourTeam" element={<TeamComponent />} />
              <Route path="/career" element={<CareerComponent />} />
              <Route path="/about" element={<AboutComponent />} />
              <Route path="/contact" element={<ContactComponent />} />
              <Route
                path="/service/itconsulting"
                element={<ServiceComponent1 />}
              />
              <Route
                path="/service/softwaredevelopment"
                element={<ServiceComponent2 />}
              />
              <Route
                path="/service/engineeringstaffing"
                element={<ServiceComponent3 />}
              />
              <Route
                path="/service/rpocontent"
                element={<ServiceComponent4 />}
              />
                <Route
                path="/service/healthcarestaffing"
                element={<ServiceComponent5 />}
              />
              <Route
                path="/contact/privacypolicy"
                element={<PrivacyComponent />}
              />
              <Route
                path="/contact/termsandcondition"
                element={<TermsComponent />}
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
