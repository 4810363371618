import React from 'react';
import "./Career.css";
import career_card_img from "../../images/career_img.jpg";
import career_work_life_img1 from "../../images/team_work.jpg";
import career_work_life_img2 from "../../images/team_work_02.jpg";

function CareerComponent() {
  return (
    <>
    <div className="py-6 md-8 text-center">
        <div className="row">
            <div className="card p-0">
                <img src={career_card_img} alt="" className="career_card_img rounded " style={{maxHeight:"350px"}} />
            <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center overlay-dark">
                <h3 className="card-title text-white mb-3">Come be a part of our team and craft your own path to a successful career!</h3>
                <a href="mailto:info@stglobaltech.com" className="career-btn btn btn-lg">Explore Opportunities</a>
            </div>
        </div>
    </div>
</div>

    <div className="container py-4 md-4">
        <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-2 mx-auto text-center">
                <span className="text-black fs-4">
                    "Are you prepared to have a positive impact? Join the ST Global team and become a crucial part of our journey towards excellence through innovation. Unleash your potential and help shape the future alongside us!"
                </span>
            </div>
        </div>
    </div>
    <div className="container py-2 md-10">
        <div className="row text-center"></div>
        <div className="row gy-6"></div>
    </div>
    <div className="container py-4 py-md-4">
        <h3 className="text-dark fw-bold">Life at ST Global</h3>
        <div className="row gy-10">
            <div className="col-lg-6 col-sm-12 col-md-6 career-card">
                <div className="card h-100 w-100">
                    <img src={career_work_life_img1} alt="" className="card-img-top" />
                    <div className="card-body">
                        <h5 className="card-title">Environment</h5>
                        <p className="card-text">
                            Join ST Global, where innovation meets expertise. Explore a dynamic environment fostering growth, collaboration, and cutting-edge solutions. Elevate your career in a forward-thinking tech consultancy.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-6 career-card">
                <div className="card h-100 w-100">
                    <img src={career_work_life_img2} alt="" className="card-img-top" />
                    <div className="card-body">
                        <h5 className="card-title">Culture</h5>
                        <p className="card-text">
                            Experience a vibrant culture at ST Global—fueled by collaboration, innovation, and a commitment to excellence. Join us for a rewarding journey in a supportive and dynamic workplace.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container py-6 md-8 mb-5">
        <div className="row text-start">
            <div className="col-12 text-dark">
                <div className="p-4 rounded" style={{backgroundColor: "#176B87", color: 'white'}}>
                    <div className="display-6 head-title text-bold">Embrace the Future with ST Global</div> We are committed to being the customer-centric partner of choice, aligning our efforts across teams and geographies to delight our clients and simplify their lives through innovative products, services, and ideas. Together, we envision a future where technology empowers businesses and individuals, and we invite you to join us on this remarkable journey of growth and excellence. 
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CareerComponent;