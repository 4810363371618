import React from 'react'
import RPO_pic1 from '../../images/rpo_pic1.jpg'
import "./ServiceComponentRPO.css"; // Import a CSS file for styling
import servicePage1 from '../../images/serviceCardComponentRPO.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSearch, faDesktop, faCalendarAlt, faCommentDots, faHandshake, faClipboardCheck} from '@fortawesome/free-solid-svg-icons';

export default function ServiceComponentRPO
    () {
    return (
        <div>
        <div className=''>
        <div className=" pb-4 pb-md-2 text-center">
                <div className="row">
                    <div className="card p-0">
                        <img src={servicePage1} alt="" style={{maxHeight : "350px"}} />
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="service-rpo-container my-3">
                <h1>Recruitement Process Outsourcing</h1>
                <p>ST Global deeply understands the challenges associated with talent acquisition, from inconsistent processes and technologies that drive up costs and push back deadlines to insufficient metrics that lead to imprecise decision-making. Our scalable, best-in-class approach to recruitment process outsourcing (RPO) allows you to attract top people while reducing expenses and time to hire. To us, RPO is more than just outsourcing the recruitment process. It’s about building a partnership to discover and deliver the talent that will have the greatest impact on your business.</p>
                <p>Our customizable end-to-end solution combines recruiting expertise with up to date technology platforms and sophisticated methodologies to help clients streamline recruitment processes, enhance candidate experience and improve quality of hire. We see talent acquisition as a critical function of the business, one that impacts not just the bottom line but one that can also drive top line growth.</p>
                <h4>In partnering with ST Global, companies can expect:</h4>
                <ul>
                    <li>
                        Better and more cost-effective processes
                    </li>
                    <li>
                        Reductions in cost and time to hire and more control over both
                    </li>
                    <li>
                        A wider and more engaged network of potential candidates
                    </li>
                    <li>
                        Enhanced employer brand and corporate reputation
                    </li>
                    <li>
                        Closer alignment of talent strategy with business strategy
                    </li>
                </ul>
                <h2>Our Recruitment Process…</h2>
      <div className="container text-center my-2">
        <img src={RPO_pic1} className="image_size" alt="Recruitment Process" />
      </div>
      <Step title="Step1: Setup" icon={faCog}>
        <ul>
          <li>Design custom recruitment process</li>
          <li>Assess past/current recruiting strategy</li>
        </ul>
      </Step>
      <Step title="Step2: Source" icon={faSearch}>
        <ul>
          <li>Building sourcing strategy</li>
          <li>Data mining</li>
          <li>Search engine optimization of requirement postings</li>
          <li>Targeted social media & event-based networking</li>
        </ul>
      </Step>
      <Step title="Step3: Screen" icon={faDesktop}>
        <ul>
          <li>Phone-screen candidates</li>
          <li>Behavioral screening</li>
          <li>Technical screening</li>
          <li>Test and assess</li>
          <li>Assessment tool recommendation</li>
          <li>Selling the job opportunity</li>
        </ul>
      </Step>
      <Step title="Step4: Schedule" icon={faCalendarAlt}>
      <ul>
          <li>Schedule interviews</li>
          <li>Confirm interviews</li>
          <li>Extensive candidate interview preparation</li>
        </ul>
      </Step>
      <Step title="Step5: Feedback" icon={faCommentDots}>
        <ul>
          <li>Gather manager feedback</li>
          <li>Gather candidate feedback</li>
        </ul>
      </Step>
      <Step title="Step6: Offer" icon={faHandshake}>
        <ul>
          <li>In-depth candidate pre-closure</li>
          <li>Negotiate and extend offers</li>
        </ul>
      </Step>
      <Step title="Step7: Onboard" icon={faClipboardCheck}>
        <ul>
          <li>Conduct background/reference/drug checks</li>
          <li>Provide new start orientation</li>
        </ul>
      </Step>
    </div>
  </div>
    )
}
    
const Step = ({ title, icon, children }) => (
    <div className="service-rpo-step">
      <h2>
        <FontAwesomeIcon icon={icon} /> {title}
      </h2>
      {children}
      </div>
)
