import React from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";
// import logo from '../images/stg_logo.png';

function Footer() {
  function scrollToTop() {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
      });
    }
  }
  
  return (
    <>
      <div className='Footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-lg-5 ft-1'>
              {/* <Link className='logo' to="/">
                <img src={logo} alt="Logo" className='logo_img' />
              </Link> */}
              <p className="footer-text">Join Our Growing List of Satisfied Clients,
                Trust ST Global for Exceptional
                Services and Elevate Your Business to New Heights.</p>
              <div className="footer-icon">
                <a href="https://www.facebook.com/people/ST-Global-LLC/100067411850116/" target="_blank" rel="noopener noreferrer">
                  <i className='fa-brands fa-facebook'></i>
                </a>
                <a href="https://www.linkedin.com/company/13253099/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
                  <i className='fa-brands fa-linkedin'></i>
                </a>
              </div>
            </div>
            <div className='col-md-6 col-lg-3 col-12 ft-2'>
              <h5>Quick Links</h5>
              <ul>
                <li className='nav-item'>
                  <Link className='' to="/about" onClick={() => { scrollToTop() }}>About</Link>
                </li>
                <li className='nav-item'>
                  <Link className='' to="/ourTeam" onClick={() => { scrollToTop() }}>Our Team</Link>
                </li>
                <li className='nav-item'>
                  <Link className='' to="/career" onClick={() => { scrollToTop() }}>Career</Link>
                </li>
                <li className='nav-item'>
                  <Link className='' to="/contact" onClick={() => { scrollToTop() }}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-6 col-lg-4 col-12 ft-3'>
              <h5>Contact Info</h5>
              <p><i className="fa-solid fa-phone"></i>+1 - 513-729-7021</p>
              <p><i className="fa-sharp fa-solid fa-envelope"></i>
              <a className="Email-footer"href="mailto:info@stglobaltech.com">info@stglobaltech.com</a>
              </p>
              <p><i className="fa-solid fa-house"></i> <u>Register Office </u></p>
              <p className='footer-address'>ST Global
                1100 State Route 222 Bethel, OH 45106
              </p>
              <p><i className="fa-solid fa-house"></i>
                <u>Corporate Office / Mailing Address</u>
              </p>
              <p className='footer-address'>1200, Camp Hill Bypass, Suite 300, Camphill
                PA-17011</p>
            </div>
          </div>
        </div>
        <div className="LastFooter">
          <p>Copyright © 2016-2024 ST Global All rights reserved.</p>
        </div>
      </div>

    </>
  )
}

export default Footer;
