import React from 'react'
import service_card_img1 from '../../images/service_card_img01.jpg'
import service_card_img2 from '../../images/service_card_img02.jpg'
import service_card_img3 from '../../images/service_card_img03.jpg'
import service_card_img4 from '../../images/service_card_img04.jpg'
import service_img4 from '../../images/service_img4.jpg'

function ServicesComponent() {
  return (
    <>
      <div className="service-container container">
        <h1 className='text-center'>Our Services</h1>
        <p className='text-center'>Partner with us to unlock a world of possibilities and build a lasting, impactful relationship.</p>
        <img className='w-100 rounded' src={service_img4} alt="" />
      </div>
      <div className="container my-5">
        <div className='row text-center my-3'>
          <h1>What We Do?</h1>
          <p p-2>The full service we are offering is specifically designed to meet your business needs and projects.</p>
        </div>
        <div className="container">
          <div className="row my-2 align-items-center justify-content-around">
            <div className="card serviceCard col-xl-6 col-lg-6 col-md-12 col-sm-12 my-2">
              <img src={service_card_img1} className="card-img-top py-2 d-block" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Empowering Your Business with Transformative Digital Innovation</h5>
                <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis nam ut et magni a non voluptates aliquid quasi sequi in laudantium eius, reiciendis libero quo doloribus ipsam inventore deleniti? Id!</p>
              </div>
            </div>
            <div className="card serviceCard col-xl-6 col-lg-6 col-md-12 col-sm-12 my-2 ">
              <img src={service_card_img2} className="card-img-top py-2 d-block" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Customer Satisfaction</h5>
                <p className="card-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem quo ad magnam voluptatibus totam similique? Nemo recusandae perspiciatis beatae quo, illum iste, neque a illo quidem officia voluptate deserunt quam!</p>
              </div>
            </div>
            <div className="card serviceCard col-xl-6 col-lg-6 col-md-12 col-sm-12 my-2">
              <img src={service_card_img3} className="card-img-top py-2 d-block" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Strategic Approach</h5>
                <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa odit molestiae nesciunt dolores ad repellat ratione provident animi non ut, molestias accusamus aliquid illo quam esse id laboriosam veniam expedita?</p>
              </div>
            </div>
            <div className="card serviceCard col-xl-6 col-lg-6 col-md-12 col-sm-12 my-2">
              <img src={service_card_img4} className="card-img-top py-2 d-block" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Strategic Approach</h5>
                <p className="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa odit molestiae nesciunt dolores ad repellat ratione provident animi non ut, molestias accusamus aliquid illo quam esse id laboriosam veniam expedita?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 service-planning">
        <div className="row text-center my-3">
          <h1>How We Do It?</h1>
        </div>
        <div className="row my-3">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <span className="icon btn btn-circle btn-lg btn-soft-primary disabled rounded-circle py-2 mb-4">
              <span className="number">01</span>
            </span>
            <h4>Planning & Preparation</h4>
            <ul>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <span className="icon btn btn-circle btn-lg btn-primary disabled rounded-circle py-2 mb-4">
              <span className="number">02</span>
            </span>
            <h4>Designing</h4>
            <ul>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <span className="icon btn btn-circle btn-lg btn-soft-primary disabled rounded-circle py-2 mb-4">
              <span className="number">03</span>
            </span>
            <h4>Building</h4>
            <ul>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <span className="icon btn btn-circle btn-lg btn-soft-primary disabled rounded-circle py-2 mb-4">
              <span className="number">04</span>
            </span>
            <h4>Deployment</h4>
            <ul>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
              <li>Organize Team</li>
            </ul>
          </div>

        </div>
      </div>
    </>
  )
}

export default ServicesComponent