import React from "react";
import sd_img1 from "../../images/Sd_1.jpg";
import sd_img2 from "../../images/Sd_2.jpg";
import "./ServiceComponentSD.css"; // Import a CSS file for styling
import servicePage1 from '../../images/serviceComponentPage2.jpg'

export default function ServiceComponentSD() {
  return (
    <>
    <div className=''>
        <div className=" pb-4 pb-md-2 text-center">
                <div className="row">
                    <div className="card p-0">
                        <img src={servicePage1} alt="" style={{maxHeight : "350px"}}/>
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
    <div className="service-sd-container my-3">
      <div className="row">
        <div className="col-lg-6  align-self-center justify-content-center ">
        <h1> What is Software Development? </h1>
        </div>
      <div className="col-lg-6 pd-3 my-3">
      <p className="lh-3">
        We boasts a rich 5-year legacy in offshore software development,
        excelling in React JS, MVC, Node, .Net, Oracle EBS, with expertise in
        full life cycle implementations, health checks, training, ERP solutions,
        and beyond. Our versatile team of 15+ resources, backed by 10,000+ hours
        of software development experience, is adept at end-to-end development,
        system design, architecture, web and mobile apps, solution engineering,
        IT staffing, and infrastructure management. We thrive on seamless
        third-party integrations, offering affordable, simple, and secure ERP
        solutions tailored for SMBs across diverse industry verticals.
      </p>
      </div>
      </div>
      <div className="container">

    
      <div className="row">
        <div className="col-lg-6">
        <div className="container text-center">
        <img src={sd_img1} className= 'image_size' alt="" />
      </div>
        </div>
        <div className="col-lg-6">
        <div className="mt-3">
        <h4>Technology Stack and Architecture Overview:</h4>
          <ul className="custom-list">
            <li>
              <b> Front end Development Tools: </b> VB.Net, React JS
            </li>
            <li>
              <b> Web Service for Business Object: </b> Node, .NET Core
            </li>
            <li>
              <b> Database: </b> SQL Server 2019
            </li>
            <li>
              <b> Development Tools: </b> MS Project, Diagramming: MS Visio,
              TFS, Bitbucket, etc.
            </li>
          </ul>
      </div>
        </div>
      </div>
      </div>
     
      <h4>Our Development Examples:</h4>
      <p>
        Our Company has developed a diverse portfolio of products, including
        ITM, FAS, STGLOBAL-POS, Exikonnect, STGLOBALQUIZ, Medireport, among
        others. Their expertise extends to various platforms, exemplified by the
        DRDP initiative covering NET, iPhone, iPad, and Android Tablet
        development. The company has undertaken multiple projects involving
        SharePoint within the Plexus framework. In collaboration with Genentech,
        they have engaged in multiple .NET-based projects. Additionally, Our
        Company has ventured into the development of tools for Epublishing,
        emphasizing the generation of web pages with meticulous adherence to
        tagging rules. Another notable endeavor is the creation of an Automation
        Warehouse Control System, showcasing their proficiency in crafting
        solutions for streamlined warehouse automation processes. These examples
        collectively underscore the breadth and versatility of ST GLOBAL's
        development capabilities across different technologies and domains.
      </p>
      <h4>Our Team Expertise:</h4>
      <p>
        Our team possesses extensive expertise across diverse platforms,
        including MVC, React JS, Node, Angular, and more, covering mobile
        development in IOS, Android, and React Native. Their proficiency extends
        to SharePoint (On-Premise, Online, Office 365), Salesforce, and a range
        of databases such as MySQL, SQL Server, Oracle, DB2, and PostgreSQL.
        Additionally, they are skilled in utilizing tools like MS Project, TFS,
        JIRA, and PowerBi, showcasing their ability to deliver comprehensive
        solutions for various development and integration needs.
      </p>
      <div className="container text-center">
        <img src={sd_img2} className= 'image_size' alt="" />
      </div>
    </div>
    </>
  );
}
