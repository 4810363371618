import React from "react";
import "./ServiceComponentHCS.css";
import health_img from '../../images/healthcare_staffing.jpg';
import servicePage1 from '../../images/servicePage4.jpg'

export default function ServiceComponentHCS() {
  return (
    <>
    <div className=''>
    <div className=" pb-4 pb-md-2 text-center">
      <div className="row">
        <div className="card p-0">
            <img src={servicePage1} alt="" style={{maxHeight : "350px"}} />
            <div className="card-img-overlay d-flex justify-content-center align-items-center">
                <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
            </div>
        </div>
      </div>
        </div>
    </div>
    <div className="service-hcs-container my-3">
      <div>
     <div className="row">
      <div className="col-lg-6 align-self-center justify-content-center ">
      <h1> What is Healthcare Staffing?</h1>
      </div>
      <div className="col-lg-6 shadow-sm my-3 pd-3">
      <p className="lh-lg">
        In the dynamic and demanding field of healthcare, having the right staff
        is crucial for ensuring exceptional patient care and operational
        efficiency. At ST Global, we specialize in providing comprehensive
        healthcare staffing solutions tailored to meet the unique needs of your
        organization. Our commitment is to deliver top-tier healthcare
        professionals who are not only skilled and experienced but also aligned
        with your organizational culture.
      </p>
      </div>
     </div>
     </div>
     
      <div>
        <h4>Our Healthcare Staffing Process:</h4>
        <ul className="custom-list">
          <li>
            <b> Needs Assessment: </b>
            <span>
              Our team engages with your organization to understand your
              staffing needs, including required skills, qualifications, and
              cultural considerations.
            </span>
          </li>
          <li>
            <b> Candidate Identification: </b>
            <span>
              Leveraging our extensive database and network, we identify and
              present candidates who match your specific requirements.
            </span>
          </li>
          <li>
            <b> Thorough Evaluation: </b>
            <span>
              Candidates undergo a comprehensive evaluation process, including
              interviews, skills assessments, and credential verification.
            </span>
          </li>
          <li>
            <b> Client Support: </b>
            <span>
              We facilitate a seamless interview process, providing support to
              both clients and candidates. Our goal is to ensure a harmonious
              match that contributes to your organization's success.
            </span>
          </li>
          <li>
            <b> Ongoing Communication: </b>
            <span>
              Post-placement, we maintain regular communication with both
              clients and candidates to ensure satisfaction and address any
              evolving needs.
            </span>
          </li>
        </ul>
      </div>
      <div className="container text-center">
        <img src={health_img} className='image_size' alt="Healthcare person handling tools" />
      </div>
    </div>
    </>
  );
}
