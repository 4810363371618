import React, { useEffect, useRef } from 'react';
import home_video from "../../images/home_video.mp4";
import crousal_img1 from "../../images/ai.jpg";
import crousal_img2 from "../../images/cloud.jpg";
import crousal_img3 from "../../images/data.jpg";
import crousal_img4 from "../../images/iot.jpg";
import crousal_img5 from "../../images/web.jpg";
import home_img_02 from "../../images/Home_img2.jpg";
import card_img1 from "../../images/content.jpg";
import service_card_img1 from '../../images/service_card_img01.jpg'
import service_card_img2 from '../../images/service_card_img02.jpg'
import service_card_img3 from '../../images/service_card_img03.jpg'
import service_card_img4 from '../../images/service_card_img04.jpg'
import { Link } from "react-router-dom";

function HomeComponent() {
  const title = 'Welcome to ST Global';
  const subtitle = 'Mapping Ideas!';
  const img_heading = 'Overview';
  const img_desc = 'As a leading IT consulting firm, we pride ourselves on delivering innovative solutions tailored to meet your unique needs. Our commitment to excellence, backed by a dynamic team of skilled professionals, positions us as your trusted partner in navigating the ever-evolving IT landscape.';

  const carouselRef = useRef(null);

  useEffect(() => {
    const carouselInstance = new window.bootstrap.Carousel(carouselRef.current, {
      interval: 5000, // Adjust the interval as needed
    });

    const intervalId = setInterval(() => {
      carouselInstance.next();
    }, 2000); // Adjust the interval as needed

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function scrollToTop() {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", // Add smooth scrolling behavior
      });
    }
  }
  const services = [
    {
      title: 'IT Consulting/Staffing',
      description: 'Dynamic IT consulting and staffing, sourcing, and recruiting top talent with unmatched speed and flexibility.',
      link: '/service/itconsulting',
      imgSrc: service_card_img1,
    },
    {
      title: 'Software Development',
      description: 'Innovative software development solutions with rapid adaptability, ensuring fresh and tailored results for your needs.',
      link: '/service/softwaredevelopment',
      imgSrc: service_card_img2,
    },
    {
      title: 'Engineering Staffing',
      description: 'Agile engineering staffing solutions, proactively recruiting top talent, ensuring adaptability to your evolving needs.',
      link: '/service/engineeringstaffing',
      imgSrc: service_card_img3,
    },
    {
      title: 'Healthcare Consulting & Staffing',
      description: 'Agile healthcare staffing proactive recruitment, rapid adaptability & tailored solutions for dynamic workforce needs.',
      link: '/service/healthcarestaffing',
      imgSrc: service_card_img4,
    },
    {
      title: 'Recruitment Process Outsourcing',
      description: 'Elevate talent acquisition with scalable, cost-effective processes, technology, and strategic alignment for growth.',
      link: '/service/rpocontent',
      imgSrc: card_img1,
    },
  ];

  const serviceContainerRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleScroll('right');
    }, 2000); // Adjust the interval as needed (e.g., 5000 milliseconds = 5 seconds)

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleScroll = (direction) => {
    const container = serviceContainerRef.current;
    const scrollAmount = 200; // Adjust as needed
    // const cardWidth = 300; // Adjust based on the width of your service cards
    // const totalCards = services.length;
  
    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else if (direction === 'right') {
      container.scrollLeft += scrollAmount;
  
      // Check if we have reached the end
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        // Move the scroll position back to the beginning
        container.scrollLeft = 0;
      }
    }
  };
  
  return (
  <>
    <div id="carouselExampleDark" className="carousel slide carousal-item home-carousal" data-bs-ride="carousel" ref={carouselRef}>
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>
        <div className="carousel-inner">
          {[crousal_img1, crousal_img2, crousal_img3, crousal_img4, crousal_img5].map((imgSrc, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? ' active' : ''}`} data-bs-interval="5000">
              <img src={imgSrc} className="w-100 page-top-banner" alt={`Slide ${index + 1}`} />
              <div className="carousel-caption d-md-block">
                <h1 className='home-title'>{title}</h1>
                <h4 className='home-title'>{subtitle}</h4>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev Home-button" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next Home-button" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
    </div>
    <div className='container text-center my-5'>
      <div className='row my-3'>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <video className='w-100' muted autoPlay loop playsInline>
            <source src={home_video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6 my-5'>
          <h3>{img_heading}</h3>
          <p className='my-2 word_spacing lh-3'>{img_desc}</p>
        </div>
      </div>
    </div>
    <div className='container text-center my-5'>
      <div className='row my-3 px-5 homeComponent-text'>
        <div className='col-sm-12 col-md12 col-lg-6'>
          <h3>What we do?</h3>
          <p className='my-2 word_spacing'>Our IT Strategy & Transformation experts help top management adopt that mindset and achieve greater business value from IT.</p>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <img src={home_img_02} alt="Teamwork" className='d-block w-100' />
        </div>
      </div>
    </div>
    <div>
      <div className="banner-exploreus text-center my-5 p-5 text-white">
        <h1 className='pd-3'>Explore US!</h1>
        <p>Explore boundless possibilities with our agile solutions—IT consulting, software development, healthcare and engineering staffing—a strategic partner for your success journey!</p>
      </div>
    </div>

    <div className='md-3'>
      <div className='row text-center my-3'>
        <h1>Why Choose Us ?</h1>
      </div>
      <div className="service-carousel-wrapper">
        <div className="service-carousel-container" ref={serviceContainerRef}>
          {/* Loop through the services to create a loop */}
          {services.map((service, index) => (
            <div className="service-card with-border" key={index}>
              <img className="card-img-top fixed-height-img" src={service.imgSrc} alt={service.title}/>
              <div className="card-body my-2">
                <h5 className="card-title text-center">{service.title}</h5>
                <p className="card-text text-center">{service.description}</p>
                <div className='text-center'>
                  <Link to={service.link} className="btn" onClick={() => { scrollToTop() }}>Read More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="scroll-button left" onClick={() => handleScroll('left')}>
          &lt;
        </button>
        <button className="scroll-button right" onClick={() => handleScroll('right')}>
          &gt;
        </button>
      </div>
    </div>

    <div className=' my-5 py-3 text-center border rounded banner'>
      <p className='fst-italic'>“Our team knows that our clients deserve our time and attention therefore we provide every single client with a high Quality product. We believe in complete 100% honesty and integrity and in producing work we can truly be proud of. Our philosophy involves working in close association with clients by understanding their specific needs and providing solutions that enable them to cope with the present day's competitive and volatile business environment”</p>
      <h4 className='fw-bold'>Sanjay Thakur</h4>
      <span className='fw-lighter'>Founder</span>
    </div>
    <div className=" my-5 border border-2 p-5 rounded">
      <h2>Unlock Success with ST Global</h2>
      <p>Discover a world of endless possibilities with ST Global. Join us on a journey of innovation and success, where your visions become reality. With our customer-centric approach, cutting-edge solutions, and unmatched expertise, we are ready to elevate your business to new heights.</p>
    </div>
  </>
  );
}

export default HomeComponent;