import React from 'react'
import "./ServiceComponentIT.css";   
import service_img1 from '../../images/services-itconsulting.png'
import service_img from '../../images/services-itconsultant.jpg'
import servicePage1 from '../../images/servicePage1.jpg'

export default function ServiceComponentITCS() {
    return (
        <>
        <div className=''>
        <div className="pb-4 pb-md-2 text-center">
                <div className="row">
                    <div className="card p-0">
                        <img src={servicePage1} alt="" style={{maxHeight : "350px"}} />
                        <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <span className="text-white p-md-2 display-4 head-title">A place where creativity meets innovation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='Servicecontainer container my-3'>
            <div className="row">
                <div className="col-lg-6 align-self-center justify-content-center">
                    <h1>What is IT Consulting/Staffing</h1>
                </div>
                <div className="col-lg-6 shadow-sm my-3 pd-3">
                    <p className='lh-lg'>In addition to providing a full range of workforce management solutions, we operate in active talent acquisition mode. We are continuously sourcing, screening and recruiting candidates for our clients. This ensures that our talent supply is always fresh – and ready to work for you! We also offer speed and flexibility: when you work with us, there’s no red tape, no time-consuming procedures, and no corporate restrictions to slow us down. We can adjust our solutions to your needs as quickly as your needs change.</p>
                </div>
            </div>
            
            <h2>Our customized staff augmentation services to help your business:</h2>
            <div className='text-center'>
            <img src={service_img} className='my-3 image_size' alt=""/>
            </div>
            
            <h3>Temporary:</h3>
            <p>Maybe you need to augment your staff for a major project or get through a busy period – without adding permanent personnel. Our roster of experienced temporary professionals fills the bill. Our carefully selected staff of temporary professionals can hit the ground running with minimal lead time. And when your project ends or you find you no longer need additional help, our temporary professionals are finished with their assignment. Easy.</p>
            <h3>Temp-to-Hire:</h3>

            <p>Temp-to-hire combines the best of temporary staffing with the best of direct hire staffing. If you are unsure whether your company will need someone long term or short term, or if you’d simply like to try out a new employee before making a commitment, temp-to-hire staffing may be right for you. One of our recruiters will advertise the position, screen resumes, interview candidates, and test candidate skills. When suitable candidates are found, we will forward their information for review and schedule interviews for you at your convenience.</p>

            <h3>Direct Hire:</h3>

            <p>Perhaps you are looking for a regular, full-time employee, but don’t have the time or resources to wade through countless applications and resumes, or to test the skills of applicants. But before you make a hiring decision, you want to be sure you have found the right person. We’ll find that person. Our team of recruiters will identify and pursue top-quality candidates – using several channels and methods. We specialize in locating passive candidates and recruiting “star” players.
            </p>
            We understand that finding Top-Notch IT professionals for your changing needs can be very complicated. We are here to assist you in sourcing the top IT talent with our experience, knowledge, and social connections. Also, we are well connected to find the right talent next door. We use our databases, latest tools, and social media in finding and building relationships with top-notch IT professionals. Our database is very clean and we always keep it up to date and our processes are streamlined, for most job requirements, we match the right candidate in a matter of hours.
            <p>We provide On-Demand recruiting solutions to ease the process of recruiting and to provide you with the right IT Talent as and when you need them.
            </p>
            <div className="container text-center">
                <img src={service_img1} className='text-center image_size' alt="" />
            </div>
        </div>
        </>
    )
}
